:root {
  /**
   * Padding
   *
   * Typically you will just set rem values directly,
   * but these shortcuts are provided when you need
   * perfect equal padding (when using bordered boxes)
   */
  --p1: 1rem;
  /* Fonts Families */
  --ffserif: CenturyStd, Times, serif;
  /* Font Sizes */
  --fs1: 1.7rem; 
  --fs2: 2.4rem;
  --fs3: 3.3rem;
  /* Line Heights */
  --lh1: 1;
  --lh2: 1.175;
  /* Fonts */
  --small: normal var(--fs1)/var(--lh2) var(--ffserif); 
  --medium: normal var(--fs2)/var(--lh2) var(--ffserif); 
  --large: normal var(--fs3)/var(--lh2) var(--ffserif); 
  /* Colors */
  --white: #fffff6;
  /* Columns */
  --c0-5: calc((1/24) * 100%);
  --c1: calc((1/12) * 100%);
  --c2: calc((2/12) * 100%);
  --c3: calc((3/12) * 100%);
  --c4: calc((4/12) * 100%);
  --c5: calc((5/12) * 100%);
  --c6: calc((6/12) * 100%);
  --c7: calc((7/12) * 100%);
  --c8: calc((8/12) * 100%);
  --c9: calc((9/12) * 100%);
  --c10: calc((10/12) * 100%);
  --c11: calc((11/12) * 100%);
  --c12: 100%;
}

/* Body */

html {
  font-size: 58%;
} 

@media (min-width: 350px) {
  html {
    font-size: 62.5%;
  }   
}

body {
  font: var(--small);
  letter-spacing: 0.025em;
  background-color: var(--white);
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* UI Reset */

b, strong {
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}

button, input {
  outline: none;
  letter-spacing: inherit;
  text-align: inherit;
}

ul, ol, li { 
  list-style: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

img, video {
  width: 100%;
  height: auto;
}

input::placeholder {
  color: black;
}

::selection {
  background: var(--white);
}

/* Type */
@font-face {
  font-family: 'CenturyStd';
  src: url('/assets/fonts/385C1B_0_0.eot');
  src: url('/assets/fonts/385C1B_0_0.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/385C1B_0_0.woff2') format('woff2'),
       url('/assets/fonts/385C1B_0_0.woff') format('woff'),
       url('/assets/fonts/385C1B_0_0.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'CenturyStd';
  src: url('/assets/fonts/385C1B_1_0.eot');
  src: url('/assets/fonts/385C1B_1_0.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/385C1B_1_0.woff2') format('woff2'),
       url('/assets/fonts/385C1B_1_0.woff') format('woff'),
       url('/assets/fonts/385C1B_1_0.ttf') format('truetype');
  font-style: italic;
}

/* Hovers */

.no-touch .hover:hover {
  color: var(--white);
}

/* Copy */

.copy {
  hyphens: auto;
  -webkit-hyphens: auto;
}

.copy > * + * {
  margin-top: 1em;
}

.copy a {
  color: var(--white);
}

.copy ul li {
  list-style: none;
  padding-left: 1em;
  position: relative;
}

.copy ul li:before {
  content: '○';
  display: block;
  position: absolute;
  left: 0;
}

.copy ol {
  counter-reset: numlist;
}

.copy ol li {
  list-style: none;
  padding-left: 1.25em;
  position: relative;
}

.copy ol li:before {
  counter-increment: numlist;
  content: counter(numlist)". ";
  display: block;
  position: absolute;
  left: 0;
}

.copy ol li:nth-child(n+10) {
  padding-left: 1.75em;
}

/* Dev */
.dev {outline:1px solid #912eff}
.dev > * {outline:1px solid #5497ff}
.dev > * > * {outline:1px solid #51feff}
.dev > * > * > * {outline:1px solid #ff0000}
.dev > * > * > * * {outline:1px solid #00ff00}